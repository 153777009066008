import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {setGameType, setGameMode, setPlayerNumber, setVersus, handleRuleChange, resetSetup, resetRules, setGamePending} from '../../store/gameSetup/gameSetupActions';
import { startMatchMaking} from '../../store/matchMaking/matchMakingActions';
import { startquickPlay } from '../../store/matchMaking/matchMakingActions';
import * as styles from '../../styles/scss/components/Dashboard/Dashboard.module.scss';
import { toggleModalSize, toggleSidebar } from "../../store/modals/modalsActions";
import { createPrivateLobby, setRedirectState } from '../../store/lobby/publicLobby/lobbyActions';
import { toggleBackdropState } from '../../store/backdropState/backdropStateActions';
import { useRouter } from 'next/router';
import PublicMMNew from '../NewWizard/PublicMMNew';
import PrivateMM from '../NewWizard/PrivateMMNew';
import { setOfflineModalState,  buzzOfflineModal} from '../../store/modals/modalsActions';
import { deviceType } from '../../helpers/functions';
import { setOnboardingTooltipCoordinates, setShowOnboardingElement } from '../../store/onBoarding/onBoardingActions';
//import { getRouterQuery } from '../../helpers/GetQueryParams';
import useTranslation from 'next-translate/useTranslation'
//import Cooldown from '../Cooldown';
import MaintenanceModeModal from '../MaintenanceModeModal';
import Backdrop from '../Backdrop/Backdrop';
//import OnboardingStepper from '../Onboarding/Stepper';
//import { Tooltip } from "../Onboarding/OnboardingTooltips";
import {cdnLoader} from '../../config/cdnImageLoader.config';

const Dashboard = (props) => {
  const {
    gameSetup,
    onGameTypeChange,
    onGameModeChange,
    onMatchMakingRequest,
    onSetPlayerNumber,
    onSetVersus,
    onRuleChange,
    OnResetSetup,
    onToggleSidebar,
    globalModalState,
    onStartQuickPlay,
    friendsList,
    friendsListAlreadyRequested,
    onlineFriendsCount,
    onCreatePrivateLobby,
    onToggleBackdropState,
    matchMakingInProgress,
    privateLobbyId,
    redirectToLobby,
    onSetRedirectState,
    onResetRules,
    activeGamesCount,
    socketId,
    //isAuthenticated,
    showOnboardingElement,
    //currentUser
  } = props

  const enumType = {
    overall: 'overall',
    solo: 'solo',
    team: 'team'
  }

  const enumFilter = {
    world: 'world',
    country: 'country',
    state: 'state',
    city: 'city',
    age: 'age',
    friends: 'friends'
  }
  //const [modalTitle, setModalTitle] = useState('');
  const [modalState, setModalState] = useState(false);
  //const [modalGameType, setModalGameType] = useState('')
  const isQuickplayDisabled = useSelector(state => state.matchMaking.searching);
  const [isMobile, setIsMobile] = useState(deviceType() === 'mobile')
  const {t} = useTranslation()
  // const showOnboardingElement = useSelector(state => state.onBoarding.showOnboardingElement);
  const onboardingInProgress = useSelector(state => state.onBoarding.onboardingInProgress);
  const {showTutorial: showTutorialAgain, maintenance} = useSelector(state => state.settings);
  const {myTurnsCount} = useSelector(state => state.auth.user)
  const {ongoingGame} = useSelector(state => state.auth.user);
  const [quickPlayDisabledLocally, setQuickplayDisabledLocally] = useState(false);
  const elementRef = useRef()
  const [maintenanceState, setMaintenanceState] = useState({
    showModal: false,
    modalType: ''
  })

  const handleCloseMaintenanceModal = (state) => {
    setMaintenanceState({
      showModal: false,
      modalType: ''
    })
  }


  const dispatch = useDispatch()
  const handleOpenOfflineModalState = (state) => {
    dispatch(setOfflineModalState(state))
  }
  const buzzModalHandler = (state) => {
    dispatch(buzzOfflineModal(state))
  }
  const router = useRouter();
  const handleGameTypeSelect = (gameType, label, enabled) => {
    if (maintenance && gameType === 'classic') {
      setMaintenanceState({
        showModal: true,
        modalType: gameType
      })
      return
    }
    // if (gameSetup.gamePending && gameType === 'classic') {
    //   return
    // }
    if (ongoingGame && gameType === 'private' && enabled) {
      //setModalTitle(label);
      onGameTypeChange(gameType);
      setModalState(true);
      //setModalGameType(gameType)
      dispatch(toggleModalSize(true))
      return
    }
    if (matchMakingInProgress || onboardingInProgress || ongoingGame) {
      dispatch(toggleModalSize(false))
      return
    }
    if (!socketId) {
      handleOpenOfflineModalState(true)
      buzzModalHandler(true)
      return
    }
    if (enabled) {
      //setModalTitle(label);
      onGameTypeChange(gameType);
      setModalState(true);
      //setModalGameType(gameType)
    }
  }

  const disableQuickplayLocally = () => {
    setQuickplayDisabledLocally(true);
    setTimeout(()=>{
      setQuickplayDisabledLocally(false);
    }, 600)
  }

  useEffect(() => {
    if (globalModalState) {
      setModalState(false)
    }
  }, [globalModalState])

  useEffect(() => {
    onToggleBackdropState(false)
    // onGetGameSetuppData()
  }, [])

  useEffect(() => {
    if (privateLobbyId && redirectToLobby) {
      onSetRedirectState(false)
      router.push(`/lobby/${privateLobbyId}`)
    }
  }, [privateLobbyId, redirectToLobby])

  // useLayoutEffect(() => {
  //     if (showOnboardingElement && elementRef.current) {
  //       if(!isSidebarOpen){
  //           const positions = elementRef.current.getBoundingClientRect()
  //           const newPositions = {
  //             x: positions.x,
  //             y: positions.y,
  //             width: positions.width,
  //             height: positions.height,
  //             tooltipWidth:document.querySelector('.onboarding_container').getBoundingClientRect().width,
  //             windowWidth: window.innerWidth
  //             }
  //             dispatch(setOnboardingTooltipCoordinates(newPositions))
  //       }
  //     }
  // }, [showOnboardingElement])

  useEffect(() => {
    if (showOnboardingElement && elementRef.current) {
        const positions = elementRef.current.getBoundingClientRect()
        const newPositions = {
          x: positions.x,
          y: positions.y,
          width: positions.width,
          height: positions.height,
          tooltipWidth: document.querySelector('.onboarding_container').getBoundingClientRect().width,
          windowWidth: window.innerWidth
        }
        dispatch(setOnboardingTooltipCoordinates(newPositions))
    }
  }, [showOnboardingElement])

  useLayoutEffect (() => {
    if (elementRef?.current) {
      dispatch(setShowOnboardingElement(showOnboardingElement))
    }
  }, [elementRef?.current])

  // useEffect(() => {
  //   if(!isSidebarOpen){
  //       if (showTutorialAgain) {
  //         dispatch(setShowOnboardingElement(showOnboardingElement))
  //         document.querySelector(`.onboardingElement[data-stepnumber='1']`).classList.add('onboardingCurrentElement')
  //     }
  //   }
  // }, [showTutorialAgain])
  useEffect(() => {
    if (showTutorialAgain) {
      dispatch(setShowOnboardingElement(showOnboardingElement))
      document.querySelector(`.onboardingElement[data-stepnumber='1']`).classList.add('onboardingCurrentElement')
    }
  }, [showTutorialAgain])

  const handleStartMatchMaking = async (params) => {
    // if (!gameSetup.selectedGameMode ||
    //     !gameSetup.selectedPlayerNumber || 
    //     // (!gameSetup.selectedRules || gameSetup.selectedRules.length === 0) ||
    //     !gameSetup.selectedVersus
    //   ) {
    //     // alert('check all rules correctly');
    //     return
    //   }

    // const matchMakingDetails = {
    //   mode: gameSetup.selectedGameMode.value,
    //   type: gameSetup.selectedGameType,
    //   players: gameSetup.selectedPlayerNumber,
    //   rules: gameSetup.selectedRules,
    //   vs: gameSetup.selectedVersus.value,
    //   region: 'global'
    // }

    //new code
    const matchMakingDetails = {
      mode: params.mode,
      type: params.type,
      players: params.players,
      rules: params.rules,
      vs: params.versus,
      region: 'global',
      name: t('common:online_game'),
    }
    onMatchMakingRequest(matchMakingDetails)
  }

  const handleCloseModal = () => {
    setModalState(false)
    OnResetSetup()
  }

  const handleGameModeChange = (gameMode) => {
    onGameModeChange(gameMode)
  }

  const [gameModesCoeficient, setGameModesCoeficient] = useState(348/336)

  const setDimensions = () => {
    const gameTypesArray = document.querySelectorAll('.game_types_li')
    const dashboardButtonsArray = document.querySelectorAll('.game_types')

    const widthX = 348 * window.innerWidth / 1280
    const heightX = widthX * gameModesCoeficient
    const heightY = 336 * pageRef.current.offsetHeight / 656
    const widthY = heightX / gameModesCoeficient
    const realHeight = Math.min(heightX, heightY)
    const realWidth = realHeight / gameModesCoeficient

    const gameTypesWidth = 350 * window.innerWidth / 2056
    let gameTypesHeight = 0

    if (window.innerWidth > 992) {
      gameTypesHeight = 120
    } else if (window.innerWidth <= 992 & window.innerWidth > 768) {
      gameTypesHeight = 100
    } else {
      gameTypesHeight = 90
    }
    for (const element of gameTypesArray) { 
      // console.log(element)
      element.style.width = realWidth + 'px'
      element.style.height = realHeight + 'px'
    }
    for (const element of dashboardButtonsArray) {
      element.style.width  = gameTypesWidth + 'px'
      element.style.height = gameTypesHeight + 'px'
    }
  }

  const pageRef = useRef()

  useEffect(() => {
    if (pageRef.current && !isMobile) {
      setDimensions()
      window.addEventListener('resize', setDimensions)
      return () => window.removeEventListener('resize', setDimensions)
    }
  }, [pageRef.current, isMobile])


  const handleQuickplay = () => {
    // throw `error_${Math.random() * 10}`
    // return
    sessionStorage.setItem("isQuickSearch", "1");
    if (maintenance) {
      setMaintenanceState({
        showModal: true,
        modalType: 'any'
      })
      return
    }
    if(isQuickplayDisabled || quickPlayDisabledLocally){
      return
    }
    disableQuickplayLocally();
    if (matchMakingInProgress || onboardingInProgress || ongoingGame) {
      dispatch(toggleModalSize(false))
      return
    }
    if (!socketId || onboardingInProgress) {
      return
    }
    if(matchMakingInProgress) {
      dispatch(toggleModalSize(false))
      return;
    }
    onStartQuickPlay(t('common:online_game'))
  }
  return (
    <>
      {/* {isAuthenticated && showOnboardingElement && router.pathname === '/' && !isSidebarOpen && (
        <>
          <div className={styles.onbording_setpper_container}>
            <OnboardingStepper />
          </div>
          <Tooltip gameType={'classic'}/>
        </>
      )} */}
    <div className={styles.dashboard} ref = {pageRef}>
          {
            gameSetup && gameSetup.dataLoaded && 
              <>
                <div className={styles.game_types_holder}>
                  <ul className={styles.game_types_ul}>
                      {gameSetup.gameTypes.map((gameType, index) => {
                        if (!gameType.enabled) {
                          return
                        }
                        return (
                          <li 
                            key={gameType.value}
                            className={
                              `${gameType.value === 'classic' && showOnboardingElement && 'onboardingCurrentElement'} ${(index === 0 || index === 3) && 'onboardingElement'} ${styles.game_types_li} game_types_li`
                            }
                            data-stepnumber = {index === 0 ? 1 : (index === 3 ? 2: 9)}
                            onClick={() => {handleGameTypeSelect(gameType.value, gameType.label, gameType.enabled)}}
                            ref={gameType.value === 'classic' ? elementRef : null}
                            >
                            <div className = {`${styles.game_type_container} ${!gameType.enabled && styles.not_available}`}>
                              {/* {gameSetup.gamePending && gameType.value === 'classic' && (
                                <div className={styles.cooldown}>
                                  <Cooldown time = {15} callBack = {() => {console.log('ended')}} />
                                </div>
                              )} */}
                              <div className={styles.game_type}>
                                <div className = {`${styles.background_1} ${styles.backgrounds}`}></div>
                                <div className = {`${styles.background_2} ${styles.backgrounds}`}></div>
                                <div className = {styles.content}>
                                  <div className = {styles.game_type_top_side}>
                                    <div className = {styles.top_content}>
                                      {gameType.value === 'team_talk' && (
                                        <div className = {styles.bubble}>
                                          <img src = {cdnLoader(`/images/dashboard/${gameType.value}_bubble.svg`)} />
                                        </div>
                                      )}
                                      <div className = {`${styles.image_container} ${styles[gameType.value]}`}>
                                        <img alt = 'game type icon' src = {cdnLoader(`/images/dashboard/${gameType.value}_upper.svg`)} />
                                        <img alt = 'game type icon' src = {cdnLoader(`/images/dashboard/${gameType.value}_bottom.svg`)} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className = {styles.game_type_bottom_side}>
                                    <h2 className = {styles.game_type_label}>{t(`dashboard:${gameType.langKey}`)}</h2>
                                    {!gameType.enabled && <div className = {styles.comming_soon}><span>{t('common:coming_soon')}</span></div>}
                                  </div>
                                  {/* {!gameType.enabled && <div className = {styles.not_available}></div>} */}
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <div 
                  className = {`${styles.dashboard_action_buttons} onboardingElement`}
                  data-stepnumber = "4"
                  data-tooltippos='top'
                  >
                  <ul className = {styles.dashboard_action_buttons_ul}>
                    <li className = {`${styles.dashboard_action_buttons_li} game_types`}>
                      <div className = {styles.action_button} 
                        onClick = {() => {
                          if (onboardingInProgress) {
                            return
                          }
                          router.push('/my-games');
                        }}>
                        <div className = {styles.action_button_content}>
                          <div className = {`${styles.background} ${styles.background_1}`}></div>
                          <div className = {`${styles.background} ${styles.background_2}`}></div>
                          <div className = {styles.action_button_container}>
                            <div className = {styles.button_image_container}>
                              <img alt="My games" src = {cdnLoader('/images/common/my-games.svg')} />
                            </div>
                            <div className = {styles.button_text_container}>
                              <h3 className = {styles.button_title}>{t(`dashboard:my_games`)} {!!activeGamesCount && <span className = {`${styles.indicator} ${styles.outer}`}>{activeGamesCount}</span>}</h3>
                              {!!activeGamesCount && <span className = {styles.button_sub_text}>{t(`dashboard:my_games_active`)}<span className = {`${styles.indicator} ${styles.inner}`}>{activeGamesCount}</span></span>}
                            </div>
                          </div>
                        </div>
                        {!!myTurnsCount && <div 
                          className={`${styles.extra_info} ${styles.my_turns_count} ${styles.outer}`}
                          >
                            <span className = {styles.button_sub_text}>{t('my_games:my_turn')}<span className= {styles.number}>{myTurnsCount}</span></span>
                          </div>}
                      </div>
                    </li>
                    <li className = {`${styles.dashboard_action_buttons_li} game_types`}>
                      <div className = {styles.action_button} 
                        onClick = {() => {
                            if (onboardingInProgress) {
                              return
                            }
                            router.push({
                              pathname : '/leaderboard', 
                              query: {tab: enumType.overall, filter: enumFilter.city}},
                              undefined,
                              {}
                            )
                          }}
                        >
                        <div className = {styles.action_button_content}>
                          <div className = {`${styles.background} ${styles.background_1}`}></div>
                          <div className = {`${styles.background} ${styles.background_2}`}></div>
                          <div className = {styles.action_button_container}>
                            <div className = {styles.button_image_container}>
                              <img alt="View your game leaderboards" src = {cdnLoader('/images/common/leaderboards.svg')} />
                            </div>
                            <div className = {styles.button_text_container}>
                              <h3 className = {styles.button_title}>{t(`dashboard:leaderboards`)}</h3>
                              <span className = {styles.button_sub_text}></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className = {`${styles.dashboard_action_buttons_li} game_types`}>
                      <div
                        className = {styles.action_button}
                        onClick = {() => {
                          if (onboardingInProgress) {
                            return
                          }
                          onToggleSidebar({
                            isOpen: true,
                            sidebarType: 'friends',
                            hasTitle: true,
                            customTitle: 'Friends',
                            showSidebarCloseButton: true
                          })
                        }}
                        >
                        <div className = {styles.action_button_content}>
                          <div className = {`${styles.background} ${styles.background_1}`}></div>
                          <div className = {`${styles.background} ${styles.background_2}`}></div>
                          <div className = {styles.action_button_container}>
                            <div className = {styles.button_image_container}>
                              <img alt="View your 
                              friends" src = {cdnLoader('/images/common/friends.svg')} />
                            </div>
                            <div className = {styles.button_text_container}>
                              <h3 className = {styles.button_title}>{t(`dashboard:friends`)}</h3>
                              <span className = {styles.button_sub_text}><span className= {`${styles.number}`}>{onlineFriendsCount}</span>{t(`common:online`)}</span>
                            </div>
                          </div>
                        </div>
                        {!!onlineFriendsCount && <div className = {`${styles.extra_info} ${styles.outer}`}><span className = {styles.button_sub_text}><span className= {styles.number}>{onlineFriendsCount}</span>{t(`common:online`)}</span></div>}
                      </div>
                    </li>
                    {/* <li className = {`${styles.dashboard_action_buttons_li} ${styles.disabled}`} onClick={() => {}}> */}
                    {/* <li className = {`${styles.dashboard_action_buttons_li}`} onClick={() => {}}>
                      <div className = {styles.action_button}
                          onClick = {() => {
                            if(onboardingInProgress) {
                              return;
                            }
                            router.push('/how-to-play', {pathname: '/how-to-play', query: getRouterQuery(router)}, {locale: router.locale})
                            // router.push('/how-to-play', {query})
                          }}
                          >
                        <div className = {styles.action_button_content}>
                          <div className = {`${styles.background} ${styles.background_1}`}></div>
                          <div className = {`${styles.background} ${styles.background_2}`}></div>
                          <div className = {styles.action_button_container}>
                            <div className = {styles.button_image_container}>
                              <img src = {cdnLoader('/images/common/tutorial.svg')} />
                            </div>
                            <div className = {styles.button_text_container}>
                              <h3 className = {styles.button_title}>{t(`dashboard:tutorial`)}</h3>
                              <span className = {styles.button_sub_text}></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div 
                  className = {`${styles.quick_play_button_container}`}
                  >
                    <div style={{
                      position: 'relative'
                    }}>
                      <button
                        className='onboardingElement'
                        data-stepnumber="3"
                        data-tooltippos='top'
                        disabled={isQuickplayDisabled || quickPlayDisabledLocally}
                        onClick = {handleQuickplay} 
                        >
                        <span>{t(`dashboard:quick_play`)}</span>
                      </button>
                      {/* {gameSetup.gamePending && (
                        <div className={styles.cooldown}>
                          <Cooldown time = {15} callBack = {() => {dispatch(setGamePending(false))}} />
                        </div>
                      )} */}
                    </div>
                </div>
                {modalState && gameSetup.selectedGameType === 'private' && 
                <PrivateMM 
                  gameSetup = {gameSetup}
                  friendsListAlreadyRequested = {friendsListAlreadyRequested}
                  friendsList = {friendsList}
                  createPrivateLobby = {onCreatePrivateLobby}
                  type = 'privateMM'
                  closeFunction = {() => {
                    setModalState(false)
                    // onToggleBackdropState(false)
                    OnResetSetup()
                  }}
                />
                }
                {modalState && gameSetup.selectedGameType === 'classic' &&                          
                  <PublicMMNew
                    gameSetup = {gameSetup}
                    handleGameModeChange = {handleGameModeChange}
                    onSetPlayerNumber = {onSetPlayerNumber}
                    onSetVersus = {onSetVersus}
                    onRuleChange = {onRuleChange}
                    onResetRules = {onResetRules}
                    handleStartMatchMaking = {handleStartMatchMaking}
                    closeFunction = {() => {
                      setModalState(false)
                      // onToggleBackdropState(false)
                      OnResetSetup()
                    }}
                  />
                }
                {maintenanceState.showModal && <>
                  <Backdrop closeFunc = {() => {handleCloseMaintenanceModal(false)}}/>
                  <MaintenanceModeModal 
                    clsoeButtonFunction = {() => {handleCloseMaintenanceModal(false)}}
                    modalType = {maintenanceState.modalType}
                    ctaAction = {() => {
                      handleCloseMaintenanceModal(false)
                      handleGameTypeSelect('private', 'Private', true)
                    }}
                  />
                </>}
            </>
          }
    </div>
    </>
  )
}


const mapStateToProps = state => {
  return {
    // gameSetup: state.gameSetup,
    globalModalState: state.modals.globalModalState,
    friendsList: state.friends.friendsList,
    friendsListAlreadyRequested: state.friends.friendsListAlreadyRequested,
    onlineFriendsCount: state.friends.onlineFriendsCount,
    matchMakingInProgress: state.matchMaking.searching,
    privateLobbyId: state.lobby.privateLobbyId,
    redirectToLobby: state.lobby.redirectToLobby,
    activeGamesCount: state.auth.user.activeGamesCount,
    socketId: state.pusher.socketId,
    isAuthenticated: state.auth.isAuthenticated,
    showOnboardingElement: state.onBoarding.showOnboardingElement,
    currentUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onMatchMakingRequest: (matchMakingDetails) => dispatch(startMatchMaking(matchMakingDetails)),
    onGameTypeChange: (gameType) => dispatch(setGameType(gameType)),
    onGameModeChange: (gameMode) => dispatch(setGameMode(gameMode)),
    onSetPlayerNumber: (playerNumber) => dispatch(setPlayerNumber(playerNumber)),
    onRuleChange: (ruleId) => dispatch(handleRuleChange(ruleId)),
    onSetVersus: (versus) => dispatch(setVersus(versus)),
    OnResetSetup: () => dispatch(resetSetup()),
    onToggleSidebar:(sidebarState) => dispatch(toggleSidebar(sidebarState)),
    onStartQuickPlay: (gameName) => dispatch(startquickPlay(gameName)),
    onCreatePrivateLobby: (payload) => dispatch(createPrivateLobby(payload)),
    onToggleBackdropState: (state) => dispatch(toggleBackdropState(state)),
    onSetRedirectState: (state) => dispatch(setRedirectState(state)),
    onResetRules: () => dispatch(resetRules())
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Dashboard));
